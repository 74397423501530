import { FilterAltIcon } from "../configuratore/icons/Icons";
import data from "../../utils/texts.json";
import {
  langStore,
  selectedVehicleStore,
  selectedRestylingStore,
  selectedSidePositionStore,
  restylingStore,
  sidePositionStore,
} from "../../utils/store";
import { useNavigateToPage } from "../../utils/functions";

export const FilterSelectedVehicle = () => {
  const navigateToPage = useNavigateToPage();
  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;
  const vehicleData = selectedVehicleStore.get();

  const selectedType = `${vehicleData.vehicleType}`;
  const vehicleTypeToDisplay = dataLanguage.vehicleIconLabel[selectedType];
  const selectedVehicle = `${vehicleData.brand.name} ${vehicleData.model.name} ${vehicleData.model.subTitle}`;

  const handleClick = () => {
    restylingStore.set([]);
    selectedRestylingStore.set("");
    sidePositionStore.set([]);
    selectedSidePositionStore.set([]);
    navigateToPage();
  };

  return (
    <div>
      <h1 className="text-4xl font-bold text-center text-text_first mb-12">
        {dataLanguage.configuratore.title}
      </h1>
      <div className="border-b py-5"></div>
      {/* Riga con filtro */}
      <div className="flex items-left  border-b py-4">
        {/* Icona e descrizione */}
        <div className="flex items-left ml-2 md:ml-5 lg:ml-10 lg:mr-[3.3px]">
          <FilterAltIcon className="w-4 h-4" />
        </div>
        <div>
          <div className="flex text-xs md:text-sm  mr-6 transform translate-y-[+3px] 	">
            <span className="capitalize text-text_second">
              {vehicleTypeToDisplay}:
            </span>
            &nbsp;
            <span className="text-text_second font-semibold ">
              {selectedVehicle}
            </span>
          </div>
        </div>

        <button
          onClick={handleClick}
          className="text-sm mr-5 pl-2 md:pl-5 lg:mr-10 font-medium text-text_gray border-l-2  cursor-pointer hover:underline hover:underline-offset-4"
        >
          {dataLanguage.productsPage.edit}
        </button>
      </div>
    </div>
  );
};
