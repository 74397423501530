import { CardSpazzole } from "./interactive-elements/CardProdotti";
import {
  langStore,
  selectedRestylingStore,
  selectedSidePositionStore,
  selectedVehicleStore,
  serverErrorStore,
} from "../../utils/store";
import data from "../../utils/texts.json";
import { SpinningLoading } from "../configuratore/icons/Icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, Fragment, useRef } from "react";
import { sendHeightToHost, useNavigateToPage } from "../../utils/functions";
import { getProducts } from "../../utils/api";
import { NoData, NoProducts, ServerError } from "../shared-elements/Alerts";
import { FilterSelectedVehicle } from "../shared-elements/FilterSelectedVehicle";
import { FilterFittingSide } from "../shared-elements/FilterFittingSide";
import { FilterRestyling } from "../shared-elements/FilterRestyling";
import { AdapterArmBox } from "../shared-elements/AdapterArmBox";
import { ImgInstructionsBox } from "../shared-elements/img-instructions/ImgInstructionsBox";
import { ImgModal } from "../shared-elements/img-instructions/ImgModal";
import { VideoInstructionsBox } from "../shared-elements/video-instructions/VideoInstructionsBox";
import { VideoModal } from "../shared-elements/video-instructions/VideoModal";
import { useStore } from "@nanostores/react";

export const PaginaProdotti = () => {
  const navigate = useNavigate();
  const vehicleData = selectedVehicleStore.get();
  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Stato per determinare se ci sono più prodotti da caricare
  const serverError = useStore(serverErrorStore);
  const [fittings, setFittings] = useState({});
  const [isImgModalOpen, setIsImgModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const hasFetched = useRef(false);
  const navigateToPage = useNavigateToPage();

  useEffect(() => {
    if (serverError === true) {
      setTimeout(() => {
        if (serverError) {
          navigateToPage();
          serverErrorStore.set(false);
        }
      }, 5000);
    }
  }, [navigateToPage, serverError]);

  useEffect(() => {
    const validateState = () => {
      if (!vehicleData || !vehicleData.brand || !vehicleData.model) {
        setShowAlert(true); // Mostra l'alert
        console.warn(dataLanguage.productsPage.noData);

        setTimeout(() => {
          navigateToPage();
        }, 5000);
      } else {
        setIsLoading(false); // I dati sono validi
      }
    };

    validateState();
  }, [vehicleData, navigate, dataLanguage.productsPage.noData, navigateToPage]);

  // TENTATIVO CARICANDO POSIZIONE E RESTYLING

  const positionId = selectedSidePositionStore.get().value;
  const restylingId = selectedRestylingStore.get()
    ? selectedRestylingStore.get().value
    : "";

  useEffect(() => {
    const fetchProducts = async () => {
      if (hasFetched.current) {
        return;
      }
      setIsLoading(true);
      try {
        const data = await getProducts(
          vehicleData.vehicleType,
          vehicleData.brand.id,
          vehicleData.model.id,
          positionId,
          restylingId
        );

        if (data.length === 0) {
          setIsLoading(false);
          setHasMore(false); // Nessun altro prodotto
        } else {
          setFittings("");
          setIsLoading(false);
          serverErrorStore.set(false); // Server funziona correttamente
          setHasMore(false); // Nessun altro prodotto
          setProducts(data.products);
          setFittings(data.fitting);
        }
      } catch (err) {
        console.error("Error fetching the products.", err);
        serverErrorStore.set(true);
      }
      hasFetched.current = true;
    };

    fetchProducts();
  }, [vehicleData, positionId, restylingId]);

  // Invia altezza iniziale dopo il caricamento degli elementi
  useEffect(() => {
    if (!isLoading) {
      sendHeightToHost();
    }
  }, [isLoading]);

  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-8 py-8 bg-white rounded-lg min-w-xs min-h-[800px]">
      <FilterSelectedVehicle />
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 mx-8">
        {/* Colonna dei filtri */}
        <div className="lg:col-span-4">
          <FilterFittingSide />
          {restylingId !== "" ? <FilterRestyling /> : ""}
          {fittings.length > 0
            ? fittings.map((fitting, index) => (
                <Fragment key={index}>
                  {(fitting.arm || fitting.adapter) && (
                    <AdapterArmBox fittings={fitting} index={index} />
                  )}

                  {/* Gestione delle immagini */}
                  {fitting.instructions.image && (
                    <>
                      <ImgInstructionsBox
                        openModal={() => setIsImgModalOpen(true)}
                        previewLink={fitting.instructions.image_preview}
                      />
                      <ImgModal
                        open={isImgModalOpen}
                        setOpen={setIsImgModalOpen}
                        instructions={fitting.instructions}
                      />
                    </>
                  )}

                  {/* Gestione dei video */}
                  {fitting.instructions.video && (
                    <>
                      <VideoInstructionsBox
                        openModal={() => setIsVideoModalOpen(true)}
                        previewLink={fitting.instructions.video_preview}
                      />
                      <VideoModal
                        open={isVideoModalOpen}
                        setOpen={setIsVideoModalOpen}
                        instructions={fitting.instructions}
                      />
                    </>
                  )}
                </Fragment>
              ))
            : null}
        </div>

        {/* Colonna dei prodotti */}
        <div className="mt-10  lg:col-span-8 max-w-full">
          {serverError ? (
            <ServerError />
          ) : showAlert ? (
            <NoData />
          ) : products.length === 0 && isLoading && hasMore ? (
            <div className="flex justify-center text-center items-center mx-auto py-4">
              <SpinningLoading />
              <span className="ml-4 text-text_second">
                {dataLanguage.productsPage.loading}
              </span>
            </div>
          ) : products.length === 0 && !hasMore && !isLoading ? (
            // no products available
            <div className="flex flex-col items-center justify-center mx-auto py-8">
              <NoProducts navigateToPage={navigateToPage} />
            </div>
          ) : (
            // Griglia dei prodotti
            <div
              id="div-cards"
              className="grid gap-y-8 gap-x-10 sm:grid-cols-2 "
            >
              {products.map((product, index) => (
                <CardSpazzole key={index} product={product} />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Disclaimer */}
      <p className="mx-6 my-6 text-justify text-text_first">
        {dataLanguage.productsPage.disclaimer}
      </p>
    </div>
  );
};
