import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { themeStore } from "./utils/store";
import { BrowserRouter } from "react-router-dom";

// Gestisce il cambio del tema dinamicamente
themeStore.subscribe((newTheme) => {
  document.body.className = ""; // Rimuove tutte le classi
  document.body.classList.add(newTheme); // Aggiunge il nuovo tema
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
