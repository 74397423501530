import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useStore } from "@nanostores/react";

import { VehicleButton } from "./interactive-elements/VehicleButton";
import { VehicleImage } from "./interactive-elements/VehicleImage";
import {
  isActive,
  getItems,
  getVehicleSide,
  checkRestyling,
} from "../../utils/api";
import {
  langStore,
  themeStore,
  tokenStore,
  activeVehicleStore,
  selectedVehicleStore,
  brandStore,
  modelStore,
  sidePositionStore,
  restylingStore,
  hasOneSideStore,
  selectedRestylingStore,
  selectedSidePositionStore,
  notAuthorizedStore,
} from "../../utils/store";
import { SearchButton } from "./interactive-elements/SearchButton";
import { SelectComboboxWithSearch } from "./interactive-elements/SelectComboboxSearch";
import data from "../../utils/texts.json";
import { useNavigate } from "react-router-dom";
import { SpinningLoading } from "./icons/Icons";
import { NotAuthorized } from "../shared-elements/Alerts";
import { resetHeight } from "../../utils/functions";

export const Configuratore = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [activeVehicleTypes, setActiveVehicleTypes] = useState(
    activeVehicleStore.get() || []
  );
  const notAuthorized = useStore(notAuthorizedStore);
  const iframeUrl = process.env.REACT_APP_IFRAME_URL;

  useEffect(() => {
    resetHeight();
  }, [isLoading]);

  useEffect(() => {
    const checkToken = async () => {
      const token = tokenStore.get();

      if (!token) {
        // Aggiungi un timeout per evitare attese infinite
        await new Promise((resolve, reject) => {
          const timeout = setTimeout(() => {
            reject(new Error("Token not available within timeout"));
          }, 2500);

          const interval = setInterval(() => {
            const currentToken = tokenStore.get();
            if (currentToken) {
              clearInterval(interval);
              clearTimeout(timeout);
              resolve();
            }
          }, 100);
        }).catch((error) => {
          console.error(error.message);
        });
      }

      const finalToken = tokenStore.get();

      if (!finalToken?.trim()) {
        notAuthorizedStore.set(true);
      }
    };

    checkToken();
  }, []);

  useEffect(() => {
    if (activeVehicleTypes.length > 0) {
      setIsLoading(false);

      return;
    }

    let isMounted = true;
    isActive()
      .then((data) => {
        if (isMounted) {
          setActiveVehicleTypes(data);
          activeVehicleStore.set(data);
        }
      })
      .catch((error) => {
        if (isMounted) {
          console.error("Errore durante il fetch dei tipi di veicolo:", error);
        }
      })
      .finally(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });

    return () => {
      isMounted = false;
    };
  }, [activeVehicleTypes.length]);

  const [vehicleType, setVehicleType] = useState(
    selectedVehicleStore.get().vehicleType || "auto"
  );
  const [selections, setSelections] = useState({
    brand: selectedVehicleStore.get().brand || null,
    model: selectedVehicleStore.get().model || null,
  });

  const [options, setOptions] = useState({
    brands: brandStore.get() || [],
    models: modelStore.get() || [],
  });

  const brandLoadingRef = useRef(false);
  const modelLoadingRef = useRef(false);

  const [emptyResults, setEmptyResults] = useState({
    brand: false,
    model: false,
  });

  const navigateToPage = async () => {
    const model = selections.model;
    if (!model) return; // Nessun veicolo selezionato

    try {
      const data = await getVehicleSide(
        vehicleType,
        selections.brand.id,
        model.id
      );

      if (data.length === 1) {
        const positionId = data[0].value;
        selectedSidePositionStore.set(data[0]);
        hasOneSideStore.set(true);

        const isRestyling = await checkRestyling(
          vehicleType,
          selections.brand.id,

          model.id,
          positionId
        );

        if (isRestyling.length > 0) {
          restylingStore.set(isRestyling);
          redirectTo("/restyling");
        } else {
          selectedRestylingStore.set("");
          redirectTo("/pagina-prodotti");
        }
      } else {
        hasOneSideStore.set(false);
        sidePositionStore.set(data);
        selectedSidePositionStore.set([]);
        redirectTo("/selezione-lato");
      }
    } catch (error) {
      console.error("Errore durante la navigazione:", error);
    }
  };

  const redirectTo = (path) => {
    const theme = themeStore.get();
    const token = tokenStore.get();
    const lang = langStore.get();

    const queryParams = new URLSearchParams({
      lang,
      theme,
      token,
    }).toString();

    if (window.WIDGET_CONFIG) {
      window.parent.postMessage(
        JSON.stringify({ type: "redirect", url: `${path}?${queryParams}` }),
        iframeUrl
      );
    } else {
      navigate(`${path}?${queryParams}`);
    }
  };

  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;
  const levels = useMemo(
    () => [
      {
        key: "brand",
        label: dataLanguage.configuratore.label.brand,
        tecdocType: "marche",
        placeholder: dataLanguage.configuratore.placeholder.brand,
      },
      {
        key: "model",
        label: dataLanguage.configuratore.label.model,
        tecdocType: "modelli",
        placeholder: dataLanguage.configuratore.placeholder.model,
      },
    ],
    [dataLanguage]
  );

  const loadOptions = useCallback(
    async (levelKey, parentId = null) => {
      if (emptyResults[levelKey]) return;
      if (options[`${levelKey}s`]?.length) return; // Già caricato

      const levelConfig = levels.find((level) => level.key === levelKey);

      let currentLoadingRef;
      if (levelKey === "brand") currentLoadingRef = brandLoadingRef;
      else if (levelKey === "model") currentLoadingRef = modelLoadingRef;

      if (currentLoadingRef.current) return;
      currentLoadingRef.current = true;

      try {
        const data = await getItems(
          levelConfig.tecdocType,
          vehicleType,
          levelKey === "model" ? parentId : selections.brand?.id
        );

        if (data.length === 0) {
          console.warn(`Nessun risultato trovato per il livello ${levelKey}`);
          setEmptyResults((prev) => ({ ...prev, [levelKey]: true }));
          return;
        }

        const loadedOptions = data.map((group) => ({
          heading: group.group.name,
          items: group.items.map((item) => ({
            id: item.id,
            name: item.title,
            subTitle: item.subTitle,
          })),
        }));

        setOptions((prevOptions) => ({
          ...prevOptions,
          [`${levelKey}s`]: loadedOptions,
        }));

        if (levelKey === "brand") brandStore.set(loadedOptions);
        if (levelKey === "model") modelStore.set(loadedOptions);
      } catch (error) {
        console.error(`${dataLanguage.configuratore.error}${levelKey}:`, error);
      } finally {
        currentLoadingRef.current = false;
      }
    },
    [
      levels,
      selections.brand?.id,
      vehicleType,
      options,
      emptyResults,
      dataLanguage.configuratore.error,
    ]
  );

  // -----------------------------
  // 11) useEffect PER EFFETTUARE LE CHIAMATE AI VARI LIVELLI
  // -----------------------------
  useEffect(() => {
    const fetchOptions = async () => {
      // Brand
      if (!selections.brand && !emptyResults.brand && !options.brands.length) {
        await loadOptions("brand");
      }

      // Model
      if (selections.brand && !emptyResults.model && !options.models.length) {
        await loadOptions("model", selections.brand.id);
      }
    };

    fetchOptions();
  }, [
    vehicleType,
    selections.brand,
    options.brands.length,
    options.models.length,
    emptyResults,
    loadOptions,
  ]);

  // -----------------------------
  // 12) handleSelect => Quando l'utente seleziona un brand/model/vehicle
  // -----------------------------
  const handleSelect = (levelKey, selectedOption) => {
    const newSelections = { ...selections, [levelKey]: selectedOption };

    if (levelKey === "brand") {
      newSelections.model = null;
      setOptions((prevOptions) => ({
        ...prevOptions,
        models: [],
      }));
      setEmptyResults({ model: false });

      loadOptions("model", selectedOption?.id);
    } else if (levelKey === "model") {
      setOptions((prevOptions) => ({ ...prevOptions }));
    }

    setSelections(newSelections);

    selectedVehicleStore.set({
      vehicleType,
      brand: newSelections.brand,
      model: newSelections.model,
    });
  };

  // -----------------------------
  // 13) Cambio tipo di veicolo => reset di tutto
  // -----------------------------
  const updateVehicleType = (type) => {
    setVehicleType(type);

    selectedVehicleStore.set({
      vehicleType: type,
      brand: null,
      model: null,
    });

    setSelections({ brand: null, model: null });
    setOptions({ brands: [], models: [] });
    setEmptyResults({ brand: false, model: false });

    // Reset delle Ref
    brandLoadingRef.current = false;
    modelLoadingRef.current = false;
  };
  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-8 py-8 bg-white rounded-lg min-w-xs min-h-[800px]">
      <h1 className="text-4xl font-bold text-center mb-12">
        {dataLanguage.configuratore.title}
      </h1>
      {notAuthorized ? (
        <NotAuthorized />
      ) : isLoading ? (
        <div className="flex justify-center text-center items-center mx-auto py-4">
          <SpinningLoading />
          <span className="ml-4 text-text_second">
            {dataLanguage.configuratore.loading}
          </span>
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center gap-3.5 lg:gap-5 mb-1 border-b">
            {activeVehicleTypes.map((type) => (
              <VehicleButton
                key={type}
                type={type}
                isSelected={vehicleType === type}
                onClick={() => updateVehicleType(type)}
                className="text-center hover:fill-primary"
              />
            ))}
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 m-4 lg:mr-8">
            <div className="flex justify-center max-w-full">
              <VehicleImage
                vehicleType={vehicleType}
                className="p-4 object-contain"
              />
            </div>
            <div>
              <div className="space-y-4 w-full mb-8">
                {levels.map((level) => (
                  <SelectComboboxWithSearch
                    key={level.key}
                    label={level.label}
                    options={options[`${level.key}s`]}
                    selectedOption={selections[level.key]}
                    onSelect={(selectedOption) =>
                      handleSelect(level.key, selectedOption)
                    }
                    disabled={level.key === "model" && !selections.brand}
                    placeholder={level.placeholder}
                  />
                ))}
                <div
                  className={`flex justify-end pt-4 ${
                    selections.model
                      ? "opacity-100"
                      : "opacity-0 pointer-events-none"
                  }`}
                >
                  <SearchButton onclick={navigateToPage} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
