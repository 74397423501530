import { langStore } from "../../../utils/store";
import data from "../../../utils/texts.json";

export const ImgInstructionsBox = ({ openModal, previewLink }) => {
  const lang = langStore.get();

  const dataLanguage = data[lang] || data.it;
  return (
    <div
      className="grid grid-cols-1 gap-4 border-b py-5  items-center hover:cursor-pointer"
      onClick={openModal}
    >
      {previewLink && (
        <img
          src={previewLink}
          alt="Istruzioni di montaggio"
          className="w-full items-center md:w-auto" // Modifica responsiva
        />
      )}

      <button className="mx-auto text-sm text-primary font-medium hover:underline hover:underline-offset-4 ">
        {dataLanguage.instructions.imgLink}
      </button>
    </div>
  );
};
