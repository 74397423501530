import data from "../../../utils/texts.json";
import { langStore } from "../../../utils/store";
import { Dropdown } from "./DropDownInfoItems";

export const CardSpazzole = ({ product }) => {
  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;
  return (
    <div className="rounded bg-white border border-border_card p-3.5 flex flex-col h-full divide-y divide-gray-200">
      {/* Sezione Immagine */}
      <div className="aspect-w-1 aspect-h-1 relative mb-4">
        <img
          src={product.images[0]?.image || ""}
          alt={dataLanguage.productsCard.imageAlt}
          className="w-full h-full object-cover"
          loading="lazy"
          onError={(e) => {
            e.target.style.display = "none";
            e.target.parentElement.style.backgroundColor = "#e5e7eb";
          }}
        />
      </div>

      {/* Sezione Contenuto Testuale */}
      <div className="flex-1 py-4">
        <span className="text-sm text-text_first capitalize ">
          {dataLanguage.productsCard.code}
        </span>
        <span className="text-sm font-bold text-text_first ">
          {product.code}
        </span>
        <span className="text-base font-bold text-text_first block ">
          {product.title}
        </span>
        {product.stock_nr && product.code !== product.stock_nr && (
          <>
            <span className="text-sm text-text_first inline capitalize">
              {dataLanguage.productsCard.stockCode}
            </span>
            <span className="text-sm font-bold text-text_first capitalize">
              {product.stock_nr}
            </span>
          </>
        )}
      </div>

      {/* Sezione Dropdown */}
      <div className="py-1.5">
        <Dropdown buttonLabel={dataLanguage.productsCard} options={product} />
      </div>
      {product.url ? (
        <div className="pt-4">
          <button
            className="bg-primary p-[18px] w-full rounded text-white"
            onClick={() => window.open(product.url, "_blank")}
          >
            {dataLanguage.productsCard.buy}
          </button>
        </div>
      ) : (
        <div className="py-[38px] w-full rounded "></div>
      )}
    </div>
  );
};
