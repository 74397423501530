import { selectedRestylingStore } from "../../utils/store";

export const PulsanteRestyling = ({ restylings, handleClick }) => {
  return (
    <div className="flex flex-col flex-wrap items-center justify-center gap-6 md:flex-row">
      {restylings.map((restyling, index) => {
        const isActive =
          selectedRestylingStore.get()?.value === restyling.value;

        return (
          <button
            key={index}
            onClick={() => {
              handleClick(restyling);
            }} // Esegui la funzione di redirezione
            className={`flex flex-col items-center w-full max-w-72 h-32 gap-4 rounded-lg border ${
              isActive
                ? "border-primary border-2 focus:ring-2 focus:ring-primary"
                : "border-gray-300 hover:border-primary"
            } justify-center focus:outline-none`}
          >
            {restyling.label && (
              <span className="text-center text-lg text-text_first">
                {restyling.label}
              </span>
            )}
            {restyling.labelDetail && (
              <span className="text-center text-base text-text_gray">
                {restyling.labelDetail}
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
};
