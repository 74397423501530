import { useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Configuratore } from "./components/configuratore/Configuratore";
import "./index.css";
import { PaginaProdotti } from "./components/prodotti/PaginaProdotti";
import { useHandleRedirection, useSetParamsFromUrl } from "./utils/functions";
import { SelezioneLato } from "./components/selezione-lato/SelezioneLato";
import { Restyling } from "./components/restyling/Restyling";

function App() {
  const location = useLocation();
  const previousPath = useRef(null);
  const iframeUrl = process.env.REACT_APP_IFRAME_URL;
  const setParamsFromURL = useSetParamsFromUrl();
  const handleRedirection = useHandleRedirection();

  useEffect(() => {
    setParamsFromURL();
    handleRedirection(previousPath, iframeUrl);
    previousPath.current = location.pathname;
  }, [location, handleRedirection, setParamsFromURL, iframeUrl]);

  return (
    <Routes>
      <Route path="/" element={<Configuratore />} />
      <Route path="/selezione-lato" element={<SelezioneLato />} />
      <Route path="/restyling" element={<Restyling />} />
      <Route path="/pagina-prodotti" element={<PaginaProdotti />} />
    </Routes>
  );
}

export default App;
