import { atom, onSet } from "nanostores";

export const langStore = atom("");

export const tokenStore = atom("");

export const themeStore = atom("tema1");

export const queryStore = atom({
  lang: langStore.get(),
  theme: themeStore.get(),
  token: tokenStore.get(),
});

// Sincronizza automaticamente `queryStore` quando uno degli store cambia
const updateQueryStore = () => {
  queryStore.set({
    lang: langStore.get(),
    theme: themeStore.get(),
    token: tokenStore.get(),
  });
};

// Aggiungi listener per ogni store
onSet(langStore, updateQueryStore);
onSet(tokenStore, updateQueryStore);
onSet(themeStore, updateQueryStore);

export const activeVehicleStore = atom([]);

export const brandStore = atom("");

export const modelStore = atom("");

export const selectedVehicleStore = atom({
  vehicleType: null,
  brand: null,
  model: null,
});

export const serverErrorStore = atom(false);

export const notAuthorizedStore = atom(false);

export const sidePositionStore = atom([]);

export const hasOneSideStore = atom(false);

export const selectedSidePositionStore = atom([]);

export const restylingStore = atom([]);

export const selectedRestylingStore = atom("");
