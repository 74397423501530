import data from "../../utils/texts.json";
import { langStore, selectedRestylingStore } from "../../utils/store";

import { useNavigateToPage } from "../../utils/functions";

export const FilterRestyling = () => {
  const navigateToPage = useNavigateToPage();

  const lang = langStore.get();

  const dataLanguage = data[lang] || data.it;
  const restyling = selectedRestylingStore.get();

  const navigateTo = () => {
    const page = "restyling";

    navigateToPage(page);
  };

  return (
    <div className="flex items-right sm:items-center lg:items-left border-b py-5">
      {/* Icona e descrizione */}

      <div className="flex w-[280px] sm:w-full">
        <div
          className="flex-grow basis-[221px]"
          // Prima colonna
        >
          <div className="text-sm mr-4 transform translate-y-[+3px] text-right">
            <span className="inline-block		">
              <span className="capitalize text-text_second font-medium  inline-block">
                {dataLanguage.productsPage.restyling}:{"\u00A0"}
              </span>
              <span className="capitalize text-text_second font-bold">
                {restyling.label} {restyling.labelDetail}
              </span>
            </span>
          </div>
        </div>
        {/* Seconda colonna */}
        <div className="flex-grow basis-[59px]">
          <button
            onClick={navigateTo}
            className="text-sm mx-0.5 text-primary font-medium  cursor-pointer hover:underline hover:underline-offset-4"
          >
            {dataLanguage.restylingPage.edit}
          </button>
        </div>
      </div>
    </div>
  );
};
