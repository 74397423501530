import {
  langStore,
  tokenStore,
  themeStore,
  serverErrorStore,
  restylingStore,
  selectedRestylingStore,
} from "../../utils/store";
import data from "../../utils/texts.json";
import { useNavigate } from "react-router-dom";
import { FilterSelectedVehicle } from "../shared-elements/FilterSelectedVehicle";
import { FilterFittingSide } from "../shared-elements/FilterFittingSide";
import { ServerError } from "../shared-elements/Alerts";
import { useStore } from "@nanostores/react";
import { useEffect } from "react";
import { useNavigateToPage } from "../../utils/functions";
import { PulsanteRestyling } from "./PulsanteRestyling";

export const Restyling = () => {
  const restylings = restylingStore.get() || [];
  const serverError = useStore(serverErrorStore);
  const navigateToPage = useNavigateToPage();

  const navigate = useNavigate();
  const lang = langStore.get();
  const theme = themeStore.get();
  const token = tokenStore.get();
  const dataLanguage = data[lang] || data.it;
  const queryParams = new URLSearchParams({
    lang,
    theme,
    token,
  }).toString();

  const navigateToProducts = async (restyling) => {
    selectedRestylingStore.set(restyling);
    navigate(`/pagina-prodotti?${queryParams}`);
  };

  useEffect(() => {
    if (serverError) {
      setTimeout(() => {
        if (serverError) {
          navigateToPage();
          serverErrorStore.set(false);
        }
      }, 5000);
    }
  }, [navigateToPage, serverError]);
  return (
    <div className="max-w-6xl mx-auto px-6 lg:px-8 py-8 bg-white rounded-lg min-w-xs min-h-[800px]">
      <FilterSelectedVehicle />
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 mx-auto max-w-full">
        <div className="lg:col-span-4">
          <FilterFittingSide />
        </div>

        <div className="mt-10  lg:col-span-8 max-w-full">
          {" "}
          <div className=" flex justify-center ">
            <h3 className="mx-auto mb-6 text-text_first text-center">
              {dataLanguage.restylingPage.selectStyle}
            </h3>
          </div>
          {serverError ? (
            <ServerError />
          ) : restylings.length ? (
            <PulsanteRestyling
              restylings={restylings}
              handleClick={navigateToProducts}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
