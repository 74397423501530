import {
  langStore,
  queryStore,
  selectedVehicleStore,
  themeStore,
  tokenStore,
} from "./store";
import { useLocation, useNavigate } from "react-router-dom";
import data from "./texts.json";
import { useRef } from "react";

const hostUrl = process.env.REACT_APP_HOST_URL;

export const sendHeightToHost = () => {
  const height = document.body.scrollHeight;
  const fixedHeight = 800;
  const finalHeight = height < fixedHeight ? fixedHeight : height;

  window.parent.postMessage(
    JSON.stringify({ type: "resize", height: finalHeight }),
    hostUrl // Sostituisci con l'host corretto
  );
};

export const resetHeight = () => {
  const height = 800;
  window.parent.postMessage(
    JSON.stringify({ type: "resize", height }),
    hostUrl
  );
};

export const scrollIframeToTop = () => {
  window.parent.postMessage(
    JSON.stringify({ type: "scrollToIframeTop" }),
    hostUrl
  );
};

export const useNavigateToPage = () => {
  const navigate = useNavigate();

  const navigateToPage = (page) => {
    try {
      // Ottieni i parametri di query dallo store
      const queryParams = new URLSearchParams(queryStore.get()).toString();

      const path = page ? `/${page}` : "/"; // Se `page` è vuoto, usa "/"

      navigate(`${path}?${queryParams}`);

      // Reimposta l'altezza della finestra, se applicabile
      sendHeightToHost();
    } catch (error) {
      console.error("Error during navigation:", error);
    }
  };

  return navigateToPage;
};

export const useSetParamsFromUrl = () => {
  const setParamsFromURL = () => {
    const params = new URLSearchParams(window.location.search);
    let lang = params.get("lang");
    let theme = params.get("theme");
    const token = params.get("token")?.split("?")[0]; // Rimuovi tutto dopo il "?"

    const acceptedLangs = ["it", "en"];
    const acceptedThemes = ["tema1", "tema2", "tema3"];

    lang = acceptedLangs.includes(lang) ? lang : "it";
    theme = acceptedThemes.includes(theme) ? theme : "tema1";

    langStore.set(lang || "it");
    themeStore.set(theme || "tema1");
    tokenStore.set(token);
    queryStore.set({ lang, theme, token });

    const title = data[lang]?.configuratore?.title || "Configuratore Spazzole";
    document.title = title;

    const description =
      data[lang]?.metaDescription || "Trova le spazzole per il tuo veicolo.";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", description);
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content = description;
      document.head.appendChild(newMetaDescription);
    }
  };
  return setParamsFromURL;
};

export const useHandleRedirection = (iframeUrl) => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousPath = useRef(null);
  const handleRedirection = () => {
    const isPaginaProdotti = location.pathname === "/pagina-prodotti";

    const vehicleData = selectedVehicleStore.get();

    try {
      const isStateValid =
        vehicleData && vehicleData.brand && vehicleData.model;

      if (isPaginaProdotti && !isStateValid) {
        const queryParams = new URLSearchParams(queryStore.get()).toString();
        if (window.WIDGET_CONFIG) {
          // Se è un iframe, invia un messaggio al sito host
          window.parent.postMessage(
            JSON.stringify({ type: "redirect", url: `/?${queryParams}` }),
            iframeUrl
          );
          resetHeight();
          scrollIframeToTop();
        } else {
          // Navigazione normale
          navigate(`/?${queryParams}`, { replace: true });
          resetHeight();
          scrollIframeToTop();
        }
      }
      if (
        previousPath.current === "/pagina-prodotti" &&
        location.pathname === "/"
      ) {
        resetHeight();
        scrollIframeToTop();
      }
    } catch (error) {
      console.error("Errore durante il reindirizzamento:", error);
    }
  };
  return handleRedirection;
};
