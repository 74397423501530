import {
  ExclamationTriangleIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";

import data from "../../utils/texts.json";
import { langStore } from "../../utils/store";

export const NoData = () => {
  const lang = langStore.get();
  const dataLanguage = data[lang];
  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="size-5 text-yellow-400"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">
            {dataLanguage.productsPage.noDataH3}
          </h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{dataLanguage.productsPage.noData}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const NoProducts = ({ navigateToPage }) => {
  const lang = langStore.get();
  const dataLanguage = data[lang];
  return (
    <div className="rounded-md bg-red-100 p-4">
      <div className="flex">
        <div className="shrink-0">
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="size-5 text-red-400"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {dataLanguage.productsPage.noProductsH3}
          </h3>
          <div className="mt-2 text-sm text-red-800">
            <p>{dataLanguage.productsPage.noProducts}</p>
          </div>
          <div className="flex justify-start mt-4">
            <button
              type="button"
              className="rounded-md  px-2 py-1.5 text-sm font-medium text-red-800 hover:ring-2 hover:ring-red-700 "
              onClick={() => navigateToPage()}
            >
              {" "}
              ← {dataLanguage.productsPage.goBack}{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ServerError = () => {
  const lang = langStore.get();
  const dataLanguage = data[lang];
  return (
    <div className="rounded-md bg-red-100 p-4">
      <div className="flex">
        <div className="shrink-0">
          <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {dataLanguage.productsPage.noDataH3}
          </h3>
          <div className="mt-2 text-sm text-red-800">
            <p>{dataLanguage.productsPage.serverError}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const NotAuthorized = () => {
  const lang = langStore.get();
  const dataLanguage = data[lang];
  return (
    <div className="rounded-md bg-red-100 p-4">
      <div className="flex">
        <div className="shrink-0">
          <XCircleIcon aria-hidden="true" className="size-5 text-red-400" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {dataLanguage.productsPage.noDataH3}
          </h3>
          <div className="mt-2 text-sm text-red-800">
            <p>{dataLanguage.configuratore.notAuthorized}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
