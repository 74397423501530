import { VehicleIcon } from "../icons/Icons";
import { langStore } from "../../../utils/store";
import data from "../../../utils/texts.json";

export const VehicleButton = ({ type, isSelected, onClick }) => {
  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;

  const label =
    type === "auto"
      ? dataLanguage.vehicleIconLabel.auto
      : type === "moto"
      ? dataLanguage.vehicleIconLabel.moto
      : type === "truck"
      ? dataLanguage.vehicleIconLabel.truck
      : type;
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-center space-x-2 pb-3  transition-colors duration-200 ${
        isSelected
          ? "text-primary border-b-2 border-primary"
          : "text-brand2-500 hover:text-primary"
      }`}
    >
      <VehicleIcon type={type} />
      <span className=" capitalize text-sm">{label}</span>
    </button>
  );
};
