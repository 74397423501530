import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export const ImgModal = ({ open, setOpen, instructions }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="relative top-0 z-10"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex items-start justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative top-0 mt-6 transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-center shadow-xl transition-all sm:my-8 w-full max-w-4xl sm:p-6"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="size-6" />
              </button>
            </div>
            <div>
              <div className="mt-3 flex flex-col items-center sm:mt-5">
                <img
                  src={instructions.image}
                  alt="Istruzioni di montaggio"
                  className="w-full items-center md:w-auto" // Modifica responsiva
                />
                {instructions.text && (
                  <div className="mt-2">
                    <div class="text-sm space-y-3 text-text_second">
                      {instructions.text}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};
