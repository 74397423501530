import { langStore } from "../../utils/store";
import data from "../../utils/texts.json";

export const AdapterArmBox = ({ fittings }) => {
  const lang = langStore.get();

  const dataLanguage = data[lang] || data.it;

  return (
    <div className="flex flex-col sm:flex-row lg:flex-col items-left border-b py-5 sm:justify-center gap-4 ">
      {/* Icona e descrizione */}

      <div className="flex items-center justify-start space-x-4">
        <div className="w-24 h-24 bg-gray-200 rounded-md grow-0 shrink-0 p-4">
          {fittings.arm_image && (
            <img
              src={fittings.arm_image}
              alt={dataLanguage.adapterArmBox.arm + " " + fittings.arm}
              className="w-full items-center md:w-auto" // Modifica responsiva
            />
          )}
        </div>
        {fittings.arm && (
          <p className="capitalize text-base text-text_second font-medium mt-0.5 ">
            {dataLanguage.adapterArmBox.arm}:{"\u00A0"}{" "}
            <span className="text-lg font-bold">{fittings.arm}</span>
          </p>
        )}
      </div>

      <div className="flex items-center justify-start space-x-4">
        <div className="w-24 h-24 bg-gray-200 rounded-md grow-0 shrink-0 p-4">
          {fittings.adapter_image && (
            <img
              src={fittings.adapter_image}
              alt={dataLanguage.adapterArmBox.adapter + " " + fittings.adapter}
              className="w-full items-center md:w-auto" // Modifica responsiva
            />
          )}
        </div>
        {fittings.adapter && (
          <p className="capitalize text-base text-text_second font-medium mt-0.5 ">
            {dataLanguage.adapterArmBox.adapter}:{"\u00A0"}{" "}
            <span className="text-lg font-bold">{fittings.adapter}</span>
          </p>
        )}
      </div>
    </div>
  );
};
