import {
  langStore,
  tokenStore,
  serverErrorStore,
  notAuthorizedStore,
} from "./store";

const baseUrl = process.env.REACT_APP_API_URL;
const configuratore = "conf-spazzole";
const versione = "v1";

const waitForLangAndToken = async () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const lang = langStore.get();
      const token = tokenStore.get();

      if (lang && token) {
        clearInterval(interval); // Stop il controllo una volta che lang è disponibile
        resolve({ lang, token });
      }
    }, 100); // Controlla ogni 100ms
  });
};

export const getItems = async (
  tecdocType,
  vehicleType,
  manufacturerId = null,
  maxRetries = 3
) => {
  if (!tecdocType || !vehicleType) {
    const error = new Error(
      "Missing required parameters: configType, tecdocType, or vehicleType"
    );
    error.statusCode = 400;
    throw error;
  }

  const getTecdoc = {
    marche: "getTecdocManufacturers.php",
    modelli: "getTecdocModels.php",
  };

  const { lang, token } = await waitForLangAndToken();

  const type = {
    auto: "auto",
    moto: "moto",
    truck: "truck",
  };

  let url = "";

  if (tecdocType === "marche") {
    url = `${baseUrl}/${configuratore}/${versione}/${
      getTecdoc[tecdocType]
    }?token=${encodeURIComponent(token)}&lang=${encodeURIComponent(
      lang
    )}&vType=${encodeURIComponent(type[vehicleType])}`;
  } else if (tecdocType === "modelli" && manufacturerId) {
    url = `${baseUrl}/${configuratore}/${versione}/${
      getTecdoc[tecdocType]
    }?token=${encodeURIComponent(token)}&lang=${encodeURIComponent(
      lang
    )}&vType=${encodeURIComponent(
      type[vehicleType]
    )}&manufacturerId=${manufacturerId}`;
  }

  let attempts = 0;
  let lastError = null;

  while (attempts < maxRetries) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("An error occurred while fetching the data.");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      attempts += 1;
      console.error(
        `Tentativo n. ${attempts + 1} di fetch fallito per ${tecdocType}:`,
        error
      );
      if ((attempts = maxRetries)) {
        break;
      }
    }
  }

  console.error(
    `Tutti i ${maxRetries} tentativi di fetch per ${tecdocType} sono falliti.`,
    lastError
  );
  throw (
    lastError || new Error("Impossibile recuperare i dati dopo i tentativi.")
  );
};

export const getProducts = async (
  vehicleType,
  manufacturerId,
  modelId,

  positionId,
  restylingId
) => {
  if (!vehicleType || !modelId) {
    throw new Error("Missing required parameters: vehicleType or vehicleId");
  }

  const getTecdoc = "getProducts.php";
  const { lang, token } = await waitForLangAndToken();

  const url = `${baseUrl}/${configuratore}/${versione}/${getTecdoc}?token=${encodeURIComponent(
    token
  )}&lang=${encodeURIComponent(lang)}&vType=${encodeURIComponent(
    vehicleType
  )}&manufacturerId=${encodeURIComponent(
    manufacturerId
  )}&modelId=${encodeURIComponent(modelId)}&restylingId=${encodeURIComponent(
    restylingId
  )}&positionId=${encodeURIComponent(positionId)}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      serverErrorStore.set(true);
      throw new Error(`Error: ${response.statusText}`);
    }
    serverErrorStore.set(false);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const getVehicleSide = async (vehicleType, manufacturerId, modelId) => {
  if (!vehicleType || !modelId) {
    const error = new Error(
      "Missing required parameters: vehicleType or vehicleId"
    );
    error.statusCode = 400;
    throw error;
  }

  const getTecdoc = "getPositions.php";
  const { lang, token } = await waitForLangAndToken();

  const url = `${baseUrl}/${configuratore}/${versione}/${getTecdoc}?token=${encodeURIComponent(
    token
  )}&lang=${encodeURIComponent(lang)}&vType=${encodeURIComponent(
    vehicleType
  )}&manufacturerId=${encodeURIComponent(
    manufacturerId
  )}&modelId=${encodeURIComponent(modelId)}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      serverErrorStore.set(true);
      throw new Error("Failed to fetch products");
    }
    serverErrorStore.set(false);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching products: ", error);
    throw error;
  }
};

export const checkRestyling = async (
  vehicleType,
  manufacturerId,
  modelId,

  positionId
) => {
  if (!manufacturerId || !positionId) {
    const error = new Error(
      "Missing required parameters: vehicleType or modelId"
    );
    error.statusCode = 400;
    throw error;
  }

  const getTecdoc = "getRestylings.php";
  const { lang, token } = await waitForLangAndToken();

  const url = `${baseUrl}/${configuratore}/${versione}/${getTecdoc}?token=${encodeURIComponent(
    token
  )}&lang=${encodeURIComponent(lang)}&vType=${encodeURIComponent(
    vehicleType
  )}&manufacturerId=${encodeURIComponent(
    manufacturerId
  )}&modelId=${encodeURIComponent(modelId)}&positionId=${encodeURIComponent(
    positionId
  )}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      serverErrorStore.set(true);

      throw new Error("Failed to fetch products");
    }
    serverErrorStore.set(false);

    const data = response.json();
    return data;
  } catch (error) {
    console.error("Error fetching products: ", error);
    throw error;
  }
};

export const isActive = async () => {
  const getVehicleType = "getVehicleType.php";

  const { lang, token } = await waitForLangAndToken();

  const url = `${baseUrl}/${configuratore}/${versione}/${getVehicleType}?token=${encodeURIComponent(
    token
  )}&lang=${encodeURIComponent(lang)}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      notAuthorizedStore.set(true);
      throw new Error("Failed to fetch products");
    }

    const data = await response.json();

    return Object.keys(data).filter((key) => data[key]);
  } catch (error) {
    notAuthorizedStore.set(true);

    console.error("Error fetching products: ", error);
    return [];
  }
};
