import data from "../../utils/texts.json";
import {
  langStore,
  hasOneSideStore,
  selectedRestylingStore,
  selectedSidePositionStore,
  restylingStore,
} from "../../utils/store";

import { useNavigateToPage } from "../../utils/functions";

export const FilterFittingSide = () => {
  const navigateToPage = useNavigateToPage();

  const lang = langStore.get();

  const dataLanguage = data[lang] || data.it;
  const selectedSide = selectedSidePositionStore.get();
  const hasOneSide = hasOneSideStore.get();

  const navigateTo = () => {
    const page = "selezione-lato";
    restylingStore.set([]);
    selectedRestylingStore.set("");
    navigateToPage(page);
  };

  return (
    <div className="flex items-right sm:items-center lg:items-left border-b py-5">
      {/* Icona e descrizione */}

      <div className="flex w-[280px] sm:w-full ">
        <div
          className={`flex-grow ${
            !hasOneSide ? "basis-[221px]" : "basis-[280px]"
          } sm:flex sm:justify-evenly lg:justify-start`} // Prima colonna
        >
          <div className=" text-sm mr-4 transform translate-y-[+3px] text-right ">
            <span className="inline-block		">
              <span className="capitalize text-text_second font-medium inline-block	">
                {dataLanguage.restylingPage.side}:{"\u00A0"}
              </span>
              <span className="capitalize text-text_second font-bold ">
                {selectedSide.label}
              </span>
            </span>
          </div>
        </div>
        {/* Seconda colonna */}
        {!hasOneSide && (
          <div className="flex-grow basis-[59px]">
            <button
              onClick={navigateTo}
              className="text-sm mx-0.5 text-primary  font-medium  cursor-pointer hover:underline hover:underline-offset-4"
            >
              {dataLanguage.restylingPage.edit}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
