import { useState, useRef, useEffect } from "react";
import { DropDownIcon, CheckIcon } from "../icons/Icons";
import data from "../../../utils/texts.json";
import { langStore } from "../../../utils/store";

export const SelectComboboxWithSearch = ({
  label,
  options,
  selectedOption,
  onSelect,
  disabled,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [hoverWidth, setHoverWidth] = useState(null); // Larghezza calcolata per hover
  const [containerWidth, setContainerWidth] = useState(0); // Larghezza w-full

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const optionRefs = useRef({});

  const lang = langStore.get();
  const dataLanguage = data[lang] || data.it;
  const filteredOptions = options
    .map((group) => ({
      heading: group.heading,
      items: group.items.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      ),
    }))
    .filter((group) => group.items.length > 0);

  const handleSelect = (option) => {
    setIsOpen(false);
    setQuery("");
    onSelect(option);
  };

  const handleBlur = (e) => {
    // Verifica se il click è avvenuto su un'opzione della dropdown
    setTimeout(() => {
      if (!dropdownRef.current.contains(document.activeElement)) {
        setIsOpen(false);
      }
    }, 150); // Ritardo per dare il tempo al click di propagarsi
  };

  useEffect(() => {
    if (dropdownRef.current) {
      setContainerWidth(dropdownRef.current.offsetWidth); // Imposta w-full come larghezza
    }
  }, [isOpen]);

  const handleHover = (id) => {
    if (optionRefs.current[id]) {
      const width = optionRefs.current[id].scrollWidth; // Calcola la larghezza
      setHoverWidth(width); // Imposta la larghezza per hover
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    if (isOpen && inputRef.current && mediaQuery.matches) {
      inputRef.current.focus();
    }
  }, [isOpen]);
  return (
    <div className="w-full my-3.5" ref={dropdownRef} onBlur={handleBlur}>
      {/* Label */}
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {label}
      </label>

      {/* Bottone principale */}
      <button
        type="button"
        className={`w-full rounded-md border ${
          disabled
            ? "bg-secondary text-text_disabled"
            : isOpen
            ? "bg-white text-text_first border-primary"
            : "bg-white text-text_first"
        } active:border-primary py-1.5 px-3 shadow-sm focus:ring-inset sm:text-sm flex items-center justify-between`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        disabled={disabled}
      >
        <span className="truncate max-w-[calc(100%-2rem)]">
          {" "}
          {/* Aggiornamento */}
          {selectedOption
            ? `${selectedOption.name}${
                selectedOption.subTitle ? ` ${selectedOption.subTitle}` : ""
              }`
            : placeholder}
        </span>
        <DropDownIcon
          className="h-5 w-5 text-text_disabled flex-shrink-0"
          aria-hidden="true"
        />
      </button>

      {/* Dropdown */}
      <div className="relative w-full">
        {isOpen && (
          <div className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-2 text-sm shadow-lg ring-1 ring-black ring-opacity-5">
            {/* Input di ricerca */}
            <div className="px-3 py-2  top-0 bg-white z-10">
              <input
                ref={inputRef}
                type="text"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                placeholder={dataLanguage.select.placeholder}
                className="w-full rounded-md border border-gray-300 py-1 px-2 text-sm text-gray-900 shadow-sm focus:ring-0 focus:border-gray-300 focus:outline-none"
              />
            </div>

            {/* Opzioni filtrate */}
            {filteredOptions.map((group) => {
              const currentWidth =
                hoverWidth && hoverWidth > containerWidth
                  ? `${hoverWidth}px`
                  : "100%"; // Controllo larghezza

              return (
                <div key={group.heading}>
                  <div className="px-3 py-1 text-text_second font-semibold">
                    {group.heading}
                  </div>
                  {group.items.map((option) => (
                    <button
                      key={option.id}
                      type="button"
                      ref={(el) => (optionRefs.current[option.id] = el)} // Assegna ref
                      onMouseEnter={() => handleHover(option.id)} // Calcola larghezza su hover
                      onClick={() => handleSelect(option)}
                      style={{
                        width: currentWidth,
                      }}
                      className="group relative w-full text-left cursor-pointer select-none py-1 px-3 hover:bg-primary  hover:text-white  flex items-center justify-between"
                    >
                      <div className="flex gap-2 items-center">
                        {/* Condizionale per mostrare l'icona CheckIcon */}
                        {selectedOption?.id === option.id && (
                          <CheckIcon className="h-5 w-5 text-primary" />
                        )}

                        {/* Modifica condizionale del padding */}
                        <span
                          className={`block truncate ${
                            selectedOption?.id === option.id ? "pl-0.5" : "pl-6"
                          }`}
                        >
                          {option.name}
                        </span>
                        {option.subTitle && (
                          <span className="text-sm text-text_third group-hover:text-white  truncate">
                            {option.subTitle}
                          </span>
                        )}
                      </div>
                    </button>
                  ))}
                </div>
              );
            })}

            {/* Nessun risultato */}
            {filteredOptions.length === 0 && (
              <div className="px-4 py-2 text-gray-500">
                {dataLanguage.select.noMatch}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
